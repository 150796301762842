var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "address-filter-container" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex w-100 google-autocomplete-input-container rounded-pill"
      },
      [
        _c("vue-google-autocomplete", {
          ref: "addresstosearchref",
          attrs: {
            id: "pac-input",
            classname: "google-autocomplete-input address-to-search",
            placeholder: _vm.$t(
              "navbar.service." + _vm.selectedDeliveryServiceId + ".searchLabel"
            ),
            types: "geocode",
            "enable-geolocation": true,
            country: "it"
          },
          on: { placechanged: _vm.addressToSearchChanged },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.searchFirst.apply(null, arguments)
            }
          }
        }),
        _vm.showNearWarehouse
          ? _c(
              "v-btn",
              {
                staticClass: "geolocate-btn",
                attrs: { color: "primary", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.resetNearWarehouse()
                  }
                }
              },
              [_c("v-icon", [_vm._v("$close")])],
              1
            )
          : _c(
              "v-btn",
              {
                staticClass: "geolocate-btn",
                attrs: { color: "primary", icon: "" },
                on: {
                  click: function($event) {
                    return _vm.geolocate()
                  }
                }
              },
              [_c("v-icon", [_vm._v("$myLocation")])],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }